import request from 'utils/request';

const api = {
  SMART_LINK_LIST: '/api/smartlink/list',
  SMART_LINK_ADD: '/api/smartlink/add',
  SMART_LINK_UPDATE: '/api/smartlink/update',
  SMART_LINK_DELETE: '/api/smartlink/delete',
};

/**
 * 查询Smart Link Offer列表
 * @param {*} query {smartLinkIds:Array<int>,status:string,page:int,pageSize:int}
 */
export function getSmartLinkList(query) {
  return request({
    url: api.SMART_LINK_LIST,
    method: 'get',
    params: query,
  });
}
/**
 * 新增smart link 设置
 * @param {*} data {smartLinkId:int,offerId:int,clickCap:int,priority:int}
 */
export function insertSmartLink(data) {
  return request({
    url: api.SMART_LINK_ADD,
    method: 'post',
    data,
  });
}
/**
 * 修改smart link 设置
 * @param {object} data {id:int,clickCap:int,priority:int}
 */
export function updateSmartLink(data) {
  return request({
    url: api.SMART_LINK_UPDATE,
    method: 'put',
    data,
  });
}
/**
 * 批量删除smart link设置
 * @param {*} ids
 */
export function deleteSmartLink(ids) {
  return request({
    url: api.SMART_LINK_DELETE,
    method: 'delete',
    params: {
      ids,
    },
  });
}

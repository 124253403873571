<template>
  <div class="smartLink">
    <div class="title">
      <h2>Smart Link</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form v-model="smartLinks" :inline="true">
              <el-form-item label="SmartLinkId" label-width="100px">
                <el-input
                  v-model="smartLinks.smartLinkId"
                  placeholder="smartLinkId here..."
                ></el-input>
              </el-form-item>
              <el-form-item label="Status">
                <el-select v-model="smartLinks.status" filterable clearable placeholder="请选择">
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="getDataList(1)">In Browser</el-button>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="addDataListClick">Add</el-button>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="delDataListClick">Delete</el-button>
              </el-form-item>
            </el-form>
          </el-card>
        </el-col>
      </el-row>
      <el-card shadow="hover">
        <el-table
          v-loading="listLoading"
          style="width:100%;"
          height="70vmin"
          stripe
          border
          :data="dataList"
          highlight-current-row
        >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-table
                @selection-change="handleDataTagsSelectionChange"
                :data="scope.row.offers"
                ref="table"
                border
                stripe
              >
                <el-table-column type="selection" align="center" width="55"></el-table-column>
                <el-table-column label="OfferId" prop="offerId" align="center" min-width="100">
                  <template slot-scope="scope">
                    <span @click="detailClick(scope.row)" class="cor337ab7">{{
                      scope.row.offerId
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="OfferName"
                  prop="offerName"
                  align="center"
                  min-width="100"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column label="Status" prop="status" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.status }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="ClickCapDaily"
                  prop="clickCapDaily"
                  align="center"
                  min-width="120"
                ></el-table-column>
                <el-table-column label="Priority" prop="priority" align="center"></el-table-column>
                <el-table-column label="SourceId" prop="sourceId" min-width="100">
                  <template slot-scope="scope">
                    <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                      scope.row.sourceId
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Countries"
                  prop="countries"
                  align="center"
                  min-width="100"
                  show-overflow-tooltip
                ></el-table-column>
                <!-- <el-table-column label="Payout" prop="payout"></el-table-column> -->
                <!-- <el-table-column label="Carriers" prop="carriers" min-width="100"></el-table-column> -->
                <el-table-column
                  label="Platforms"
                  prop="platforms"
                  min-width="100"
                ></el-table-column>
                <!-- <el-table-column
                  label="CreateTime"
                  prop="createTime"
                  align="center"
                  min-width="100"
                ></el-table-column> -->
                <el-table-column fixed="right" label="Option" min-width="120" align="center">
                  <template slot-scope="scope">
                    <el-button type="primary" @click="updateSmartLinksClick(scope.row)"
                      >Update</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column label="SmartLinkId" prop="smartLinkId" align="center" min-width="110">
            <template slot-scope="scope">
              <span class="cor337ab7" @click="smdetailClick(scope.row)">{{
                scope.row.smartLinkId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Status"
            prop="status"
            align="center"
            min-width="90"
          ></el-table-column>
          <el-table-column
            label="Name"
            prop="name"
            align="center"
            min-width="100"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="SourceId" prop="sourceId" align="center" min-width="100">
            <template slot-scope="scope">
              <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                scope.row.sourceId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Countries"
            prop="countries"
            min-width="100"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="Platforms"
            prop="platforms"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column
            label="Revenue"
            prop="revenue"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column label="Payout" prop="payoutValue" align="center"></el-table-column>
          <el-table-column
            label="Operator"
            prop="operator"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="Operate Time"
            prop="operateTime"
            min-width="110"
          ></el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageParam.pageSize"
          :currentPage="pageParam.page"
        ></pagination>
      </el-card>
      <!-- 添加模态框 -->
      <el-dialog
        :visible.sync="addSmartLinkVisible"
        :close-on-click-modal="false"
        :append-to-body="true"
        title="Add SmartLink"
      >
        <el-form label-position="left" :model="addSmartLinks" label-width="100px">
          <el-row v-if="isUpdate !== true">
            <el-col :lg="11" :md="20">
              <el-form-item label="SmartLinkId:">
                <el-input
                  v-model="addSmartLinks.smartLinkId"
                  placeholder="smartLinkId here..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="20" :offset="1">
              <el-form-item label="OfferId:">
                <el-input v-model="addSmartLinks.offerId" placeholder="offerId here..."></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :lg="11" :md="20">
              <el-form-item label="ClickCap:">
                <el-input
                  v-model="addSmartLinks.clickCap"
                  placeholder="clickCap here..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="20" :offset="1">
              <el-form-item label="Priority:">
                <el-input
                  v-model="addSmartLinks.priority"
                  placeholder="priority here..."
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addSmartLinkVisible = false">取 消</el-button>
          <el-button type="primary" @click="addOrUpdateApi">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import {
    getSmartLinkList,
    insertSmartLink,
    deleteSmartLink,
    updateSmartLink,
  } from 'api/product/smartLink';
  import Pagination from '@/components/pagination';
  import { myMixin } from '@/mixins/mixins.js';

  let clickTimer = null;
  export default {
    mixins: [myMixin],
    components: {
      Pagination,
    },
    data() {
      return {
        smartLinks: {
          status: 'active',
          smartLinkId: null,
        },
        listLoading: false,
        addSmartLinkVisible: false,
        dataList: [],
        total: null,
        pageParam: {
          page: 1,
          pageSize: 10,
        },
        statusOptions: [
          {
            label: 'ACTIVE',
            value: 'active',
          },
          {
            label: 'PENDING',
            value: 'pending',
          },
          {
            label: 'PAUSED',
            value: 'paused',
          },
          {
            label: 'HOLD',
            value: 'hold',
          },
          {
            label: 'BLOCK',
            value: 'block',
          },
          {
            label: 'ALL',
            value: 'ALL',
          },
        ],
        addSmartLinks: {
          smartLinkId: null,
          offerId: null,
          clickCap: null,
          priority: null,
        },
        updateId: null,
        isUpdate: false,
        dataSelectionList: [],
        delArr: [],
      };
    },
    mounted() {
      this.getDataList();
    },
    methods: {
      getDataList(curPage) {
        if (curPage) {
          this.pageParam.page = curPage;
        }
        let param = {
          ...this.smartLinks,
          page: this.pageParam.page,
          pageSize: this.pageParam.pageSize,
        };

        getSmartLinkList(param).then((response) => {
          if (response.code == 200) {
            this.dataList = response.result;
            this.total = response.total;
          } else {
            this.$message.error(response.status.msg);
          }
        });
      },
      addDataListClick() {
        this.isUpdate = false;
        this.setNull(this.addSmartLinks);
        this.addSmartLinkVisible = true;
      },
      handleDataTagsSelectionChange(val) {
        this.dataSelectionList = val;
      },
      delDataListClick() {
        const length = this.dataSelectionList.length;
        this.$confirm('Are you want to delete ?', 'Tip', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        }).then(() => {
          for (let i = 0; i < length; i++) {
            this.delArr.push(this.dataSelectionList[i].id);
          }

          deleteSmartLink(this.delArr.join()).then((response) => {
            if (response.code == 200) {
              this.$message({
                message: 'Delete Success',
                type: 'success',
              });
              this.getDataList();
            } else {
              this.$message.error('Delete Error:' + response.message);
            }
          });
        });
        this.delArr = [];
      },
      updateSmartLinksClick(row) {
        this.isUpdate = true;
        this.updateId = row.id;
        this.addSmartLinkVisible = true;
      },
      handleSizeChange(size) {
        this.pageParam.pageSize = size;
        this.handleCurrentChange(1);
      },
      async handleCurrentChange(currentPage) {
        this.pageParam.page = currentPage;
        this.offerListApi();
      },
      addOrUpdateApi() {
        let param;
        if (this.isUpdate) {
          param = {
            id: this.updateId,
            clickCap: this.addSmartLinks.clickCap,
            priority: this.addSmartLinks.priority,
          };
          updateSmartLink(param).then((response) => {
            if (response.code === 200) {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
              this.getDataList();
            } else {
              this.$message.error('Update Error:' + response.message);
            }
          });
        } else {
          insertSmartLink({ ...this.addSmartLinks }).then((response) => {
            if (response.code == 200) {
              this.$message({
                message: 'Add Success',
                type: 'success',
              });
              this.getDataList();
            } else {
              this.$message.error('Add Error:' + response.message);
            }
          });
        }
        this.addSmartLinkVisible = false;
        this.isUpdate = false;
      },
      detailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        var that = this;
        clickTimer = window.setTimeout(function() {
          that.$nextTick(() => {
            const { href } = that.$router.resolve({
              path: '/offer/detail',
              query: {
                offerId: row.offerId,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
      smdetailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        var that = this;
        clickTimer = window.setTimeout(function() {
          that.$nextTick(() => {
            const { href } = that.$router.resolve({
              path: '/offer/detail',
              query: {
                offerId: row.smartLinkId,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
      sourcedetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/source/detail',
          query: {
            sourceId: row.sourceId,
          },
        });
        window.open(href, '_blank');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
</style>
